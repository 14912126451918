<template>
<div class="container">

	 <div class="row">
		<QuizEditorHeader
					:quiz="quiz"
					:quizTitle="quizTitle"
					@getQuiz="getQuiz"
				></QuizEditorHeader
			>
	 </div>

	 <div class="row mt-10">
		<div class="col-12">

		<QuizEditorContent
					:quiz="quiz"
					:quizTitle="quizTitle"
					@addNewQuestion="addNewQuestion"
					@save="save"
					@remove="remove"
					@changeIndex="changeIndex"
				></QuizEditorContent>

				</div>
	 </div>

</div>
	<!-- <v-col cols="12">
		<v-row>
			<v-col cols="12">
				<QuizEditorHeader
					:quiz="quiz"
					:quizTitle="quizTitle"
					@getQuiz="getQuiz"
				></QuizEditorHeader
			></v-col>
		</v-row>
		<v-row class="quizes-editor-main">
			<v-col cols="10">
				<QuizEditorContent
					:quiz="quiz"
					:quizTitle="quizTitle"
					@addNewQuestion="addNewQuestion"
					@save="save"
					@remove="remove"
					@changeIndex="changeIndex"
				></QuizEditorContent>
			</v-col>
		</v-row>
		<v-row>
			<v-col
				><form-message v-if="message !== ''" :type="status">{{
					message
				}}</form-message></v-col
			>
		</v-row>
	</v-col> -->
</template>

<script>
import QuizEditorHeader from "@/views/admin/quiz-editor/QuizEditorHeader.vue";
import QuizEditorContent from "@/views/admin/quiz-editor/QuizEditorContent.vue";
import { get } from "@/util/requests/get";
import { remove } from "@/util/requests/remove";
import FormMessage from "@/components/FormMessage.vue";
import { put } from "@/util/requests/put";
import { mapGetters } from "vuex";

export default {
	name: "QuizEditor",

	components: {
		QuizEditorHeader,
		QuizEditorContent,
		FormMessage
	},

	computed: {
		...mapGetters(["organization"]),
		quizTitle() {
			if (!this.quiz.name) return "";
			return this.quiz?.name;
		}
	},
	data() {
		return {
			displayPopup: false,
			rules: {
				required: value => !!value || "Required."
			},
			quiz: {},
			message: "",
			status: ""
		};
	},
	methods: {
		setMessage(val) {
			this.message = val;
		},
		async getQuiz() {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await get(`/quiz/${this.$route.params.id}`);
				const {
					data: { data }
				} = response;
				this.quiz = data;
			} catch (error) {
				await this.setMessage(error.response.data.message);
				this.status = error.response.data.message;
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		addNewQuestion() {
			this.$router.push({
				name: "QuizEditorAddNewQuestion",
				params: { id: this.$route.params.id }
			});
		},
		async changeIndex(payload) {

			let sort = payload.sort
			let id

			let load = {
				content: payload.content,
				sort_order: sort + payload.mark
			}

			if(payload.type == 'question') {
				load.type = 'question'
				load.label = 'question'
				id = payload.questionId
			} else {
				id = payload.responseId
				load.question_id = id
			}

			await put("/" + payload.type, id, load);
			await this.getQuiz();
			// console.log(payload)

		},
		async remove({ id, type }) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await remove(`/${type}/${id}`);
				if (response.status === 200) {
					await this.getQuiz();
				}
			} catch (error) {
				console.error(error);
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async save({ type, id, content, object }) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await put(`/${type}`, id, {
					...object,
					content: content
				});
				if (response.status === 200) {
					await this.getQuiz();
				}
			} catch (error) {
				await this.setMessage(error.response?.data.message);
				this.status = error.response?.data.message;
			}
			await this.$store.dispatch("setIsLoading", false);
		},

		async saveQuestion(payload) {
			try {
				await this.$store.dispatch("setIsLoading", true);
				const response = await put("/question", payload.questionId, {
					label: payload.question.label,
					content: payload.content,
					help_html: payload.question.help_html,
					help_text: payload.question.help_text,
					type: payload.question.type,
					required: payload.question.required,
					sort_order: payload.question.sort_order,
					custom: payload.question.custom,
					organization_id: this.organization.id,
					category_id: payload.question.category_id
				});
				if (response.status === 200) {
					await this.getQuiz();
				}
			} catch (error) {
				console.log(error);
				await this.setMessage(error.response?.data.message);
				this.status = error.response?.data.message;
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async saveResponse(payload) {
			console.log(payload);
		}
	},
	async created() {
		await this.getQuiz();
	}
};
</script>

<style></style>
